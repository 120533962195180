import Vue from 'vue';
import Router from 'vue-router'
import Login from '@/components/Login'
import Manage from '@/components/Manage'
import Home from '@/components/Home'
import User from '@/components/system/User'
import UserDetail from '@/components/system/UserDetail'
import Menu from '@/components/system/Menu'
import Role from '@/components/system/Role'
import Operation from '@/components/system/Operation'
import Dict from '@/components/system/Dict'

import RealName from '@/components/RealName'

import ArticleList from '@/components/article/List'
import ArticleDetail from '@/components/article/Detail'
import ArticleComment from '@/components/article/Comment'
import ArticleReply from '@/components/article/Reply'
import ArticleVerify from '@/components/article/Verify'
import ArticleIncomeVerify from '@/components/article/IncomeVerify'

import PullWire from '@/components/pullWire/Many'
import AddFriend from '@/components/pullWire/One'

import Gift from '@/components/Gift'

import Product from '@/components/product/Product'

import Chat from '@/components/chat/Chat'
import ChatPage from '@/components/chat/ChatPage'


import Version from '@/components/Version'

import Comment from '@/components/safe/Comment'
import Reply from '@/components/safe/Reply'

import Log from '@/components/monitor/Log'
import Report from '@/components/monitor/Report'
import Question from '@/components/monitor/Question'
import BanAppeal from '@/components/monitor/BanAppeal'
import AppLoginLog from '@/components/monitor/AppLoginLog'
import RefundRecord from '@/components/monitor/RefundRecord'
import UserFoul from '@/components/monitor/UserFoul'
import CommentRisk from '@/components/monitor/CommentRisk'
import DeviceBlack from '@/components/monitor/DeviceBlack'

import TakeMoney from '@/components/money/TakeMoney'
import CoinOrder from '@/components/money/CoinOrder'
import CoinRecord from '@/components/money/CoinRecord'
import Balance from '@/components/money/Balance'

import Banner from '@/components/other/Banner'
import DeleteConfig from '@/components/other/DeleteConfig'
import SmallStore from '@/components/other/SmallStore'
import Course from '@/components/other/Course'
import CourseCategory from '@/components/other/CourseCategory'
import Face from '@/components/other/Face'

import GoldMaster from '@/components/customized/GoldMaster'
import BeautyGirl from '@/components/customized/BeautyGirl'
import CustomApply from '@/components/customized/CustomApply'
import CustomSuccess from '@/components/customized/CustomSuccess'
import SaveQrcode from '@/components/customized/SaveQrcode'

import EveryDay from '@/components/operate/EveryDay'
import Channel from '@/components/operate/Channel'
import Statistics from '@/components/operate/Statistics'
import RegisterData from '@/components/operate/RegisterData'

import ActivityReward from '@/components/activity/ActivityReward'
import RewardReview from '@/components/activity/RewardReview'

import ServiceSettings from '@/components/service/ServiceSettings'
import SendMsg from '@/components/service/SendMsg'


import VipList from '@/components/vip/List.vue'
import VipPaymentRecords from '@/components/vip/PaymentRecords.vue'
import VipPrice from '@/components/vip/Price.vue'
import VipPower from '@/components/vip/Power.vue'
import HighCornet from '@/components/vip/HighCornet.vue'

import InviteIncome from '@/components/invite/income.vue'
import InviteRegister from '@/components/invite/register.vue'
import InviteShare from '@/components/invite/share.vue'
import RewardRatio from '@/components/invite/rewardRatio.vue'

import Notice from '@/components/Notice.vue'

import FriendsApply from '@/components/friends/Apply.vue'
import FriendsPublish from '@/components/friends/Publish.vue'
import FriendsPublishDetail from '@/components/friends/PublishDetail.vue'
import FriendsFeedback from '@/components/friends/Feedback.vue'
import FriendsTags from '@/components/friends/Tags.vue'

import MatchGreet from '@/components/match/Greet.vue'
import MatchReport from '@/components/match/Report.vue'
import MatchProduct from '@/components/match/Product.vue'
import MatchRecord from '@/components/match/Record.vue'
import MatchUsageRecord from '@/components/match/UsageRecord.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Login
    },
    {
      path:'*', //输入的路径不存在时，跳转的界面，可以是404，也可以是登录界面
      component:Login
    },
    {
      path: '/manage',
      component: Manage,
      alias: '/manage.html',
      children: [
        {
          path: '/home',
          component: Home,
          alias: '/home.html'
        },
        {
          path: '/user',
          component: User,
          alias: '/user.html'
        },
        {
          path: '/user/:id',
          component: UserDetail,
          alias: '/user-detail.html'
        },
        {
          path: '/userNumber/:number',
          component: UserDetail,
          alias: '/user-detail.html'
        },
        {
          path: '/menu',
          component: Menu,
          alias: '/menu.html'
        },
        {
          path: '/role',
          component: Role,
          alias: '/role.html'
        },
        {
          path: '/operation',
          component: Operation,
          alias: '/operation.html'
        },
        {
          path: '/dict',
          component: Dict,
          alias: '/dict.html'
        },
        {
          path: '/realName',
          component: RealName,
          alias: '/realName.html'
        },
        {
          path: '/article',
          component: ArticleList,
          alias: '/article.html'
        },
        {
          path: '/article/:id',
          component: ArticleDetail,
          alias: '/articleDetail.html'
        },
        {
          path: '/articleComment',
          component: ArticleComment,
          alias: '/articleComment.html'
        },
        {
          path: '/articleReply',
          component: ArticleReply,
          alias: '/articleReply.html'
        },
        {
          path: '/articleVerify',
          component: ArticleVerify,
          alias: '/articleVerify.html'
        },
        {
          path: '/articleIncomeVerify',
          component: ArticleIncomeVerify,
          alias: '/articleIncomeVerify.html'
        },
        {
          path: '/pullWire',
          component: PullWire,
          alias: '/pullWire.html'
        },
        {
          path: '/addFriend',
          component: AddFriend,
          alias: '/addFriend.html'
        },
        {
          path: '/takeMoney',
          component: TakeMoney,
          alias: '/takeMoney.html'
        },
        {
          path: '/gift',
          component: Gift,
          alias: '/gift.html'
        },
        {
          path: '/product',
          component: Product,
          alias: '/product.html'
        },
        {
          path: '/chat',
          component: Chat,
          alias: '/chat.html'
        },
        {
          path: '/chatPage',
          component: ChatPage,
          alias: '/chatPage.html'
        },
        {
          path: '/log',
          component: Log,
          alias: '/log.html'
        },
        {
          path: '/version',
          component: Version,
          alias: '/version.html'
        },
        {
          path: '/comment',
          component: Comment,
          alias: '/comment.html'
        },
        {
          path: '/reply',
          component: Reply,
          alias: '/reply.html'
        },
        {
          path: '/report',
          component: Report,
          alias: '/report.html'
        },
        {
          path: '/question',
          component: Question,
          alias: '/question.html'
        },
        {
          path: '/coinOrder',
          component: CoinOrder,
          alias: '/coinOrder.html'
        },
        {
          path: '/coinRecord',
          component: CoinRecord,
          alias: '/coinRecord.html'
        },
        {
          path: '/balance',
          component: Balance,
          alias: '/balance.html'
        },
        {
          path: '/banner',
          component: Banner,
          alias: '/banner.html'
        },
        {
          path: '/deleteConfig',
          component: DeleteConfig,
          alias: '/deleteConfig.html'
        },
        {
          path: '/smallStore',
          component: SmallStore,
          alias: '/smallStore.html'
        },
        {
          path: '/courseCategory',
          component: CourseCategory,
          alias: '/courseCategory.html'
        },
        {
          path: '/course',
          component: Course,
          alias: '/course.html'
        },
        {
          path: '/face',
          component: Face,
          meta: {type: 0},
          alias: '/face.html'
        },
        {
          path: '/emojiLibrary',
          component: Face,
          meta: {type: 1},
          alias: '/emojiLibrary.html'
        },
        {
          path: '/goldMaster',
          component: GoldMaster,
          alias: '/goldMaster.html'
        },
        {
          path: '/beautyGirl',
          component: BeautyGirl,
          alias: '/beautyGirl.html'
        },
        {
          path: '/customApply',
          component: CustomApply,
          alias: '/customApply.html'
        },
        {
          path: '/customSuccess',
          component: CustomSuccess,
          alias: '/customSuccess.html'
        },
        {
          path: '/saveQrcode',
          component: SaveQrcode,
          alias: '/saveQrcode.html'
        },
        {
          path: '/everyday',
          component: EveryDay,
          alias: '/everyday.html'
        },
        {
          path: '/channel',
          component: Channel,
          alias: '/channel.html'
        },
        {
          path: '/statistics',
          component: Statistics,
          alias: '/statistics.html'
        },
        {
          path: '/registerData',
          component: RegisterData,
          alias: '/registerData.html'
        },
        {
          path: '/banAppeal',
          component: BanAppeal,
          alias: '/banAppeal.html'
        },
        {
          path: '/appLoginLog',
          component: AppLoginLog,
          alias: '/appLoginLog.html'
        },
        {
          path: '/activityReward',
          component: ActivityReward,
          alias: '/activityReward.html'
        },
        {
          path: '/rewardReview/:type',
          component: RewardReview,
          alias: '/rewardReview.html'
        },
        {
          path: '/serviceSettings',
          component: ServiceSettings,
          alias: '/serviceSettings.html'
        },
        {
          path: '/sendMsg',
          component: SendMsg,
          alias: '/sendMsg.html'
        },
        {
          path: '/refundRecord',
          component: RefundRecord,
          alias: '/refundRecord.html'
        },
        {
          path: '/userFoul',
          component: UserFoul,
          alias: '/userFoul.html'
        },
        {
          path: '/commentRisk',
          component: CommentRisk,
          alias: '/commentRisk.html'
        },
        {
          path: '/deviceBlack',
          component: DeviceBlack,
          alias: '/deviceBlack.html'
        },
        {
          path: '/vipList',
          component: VipList,
          alias: '/vipList.html'
        },
        {
          path: '/vipPaymentRecords',
          component: VipPaymentRecords,
          alias: '/vipPaymentRecords.html'
        },
        {
          path: '/vipPrice',
          component: VipPrice,
          alias: '/vipPrice.html'
        },
        {
          path: '/vipPower',
          component: VipPower,
          alias: '/vipPower.html'
        },
        {
          path: '/highCornet',
          component: HighCornet,
          alias: '/highCornet.html'
        },
        {
          path: '/inviteIncome',
          component: InviteIncome,
          alias: '/inviteIncome.html'
        },
        {
          path: '/inviteRegister',
          component: InviteRegister,
          alias: '/inviteRegister.html'
        },
        {
          path: '/inviteShare',
          component: InviteShare,
          alias: '/inviteShare.html'
        },
        {
          path: '/rewardRatio',
          component: RewardRatio,
          alias: '/rewardRatio.html'
        },
        {
          path: '/notice',
          component: Notice,
          alias: '/notice.html'
        },
        {
          path: '/friendsApply',
          component: FriendsApply,
          alias: '/friendsApply.html'
        },
        {
          path: '/friendsPublish',
          component: FriendsPublish,
          alias: '/friendsPublish.html'
        },
        {
          path: '/friendsPublishDetail/:id',
          component: FriendsPublishDetail,
          alias: '/friendsPublishDetail.html'
        },
        {
          path: '/friendsFeedback',
          component: FriendsFeedback,
          alias: '/friendsFeedback.html'
        },
        {
          path: '/friendsTags',
          component: FriendsTags,
          alias: '/friendsTags.html'
        },
        {
          path: '/matchGreet',
          component: MatchGreet,
          alias: '/matchGreet.html'
        },
        {
          path: '/matchReport',
          component: MatchReport,
          alias: '/matchReport.html'
        },
        {
          path: '/matchCard',
          component: MatchProduct,
          meta: {type: 0},
          alias: '/matchCard.html'
        },
        {
          path: '/cityCard',
          component: MatchProduct,
          meta: {type: 1},
          alias: '/cityCard.html'
        },
        {
          path: '/matchRecord',
          component: MatchRecord,
          alias: '/matchRecord.html'
        },
        {
          path: '/matchUsageRecord',
          component: MatchUsageRecord,
          alias: '/matchUsageRecord.html'
        },
      ],
    }
  ]
})

//路由导航，每次进入新的路由时判断是否需要重新登录
router.beforeEach((to, from, next) => {
  //判断token是否存在
  let token = localStorage.getItem('token')
  if(to.path=='/login' || token) {
    next()
  }else {
    setTimeout(() => {
      next({
        path: '/login'
      })
    }, 500);
  }
})

export default router

